@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  color: inherit;
  text-decoration: none;
  &:hover {
    :global {
      h3 {
        color: @color-black-075;
      }
    }
  }
}
.containerVertical {
  .container;
  display: flex;
  flex-direction: column;

  &:hover {
    img {
      box-shadow: @default-box-shadow;
    }
  }

  img {
    width: 100%;
    border-radius: 4px;
  }
}
.containerHorizontal {
  .container;
  display: flex;
  flex-direction: row;
  background-color: @color-white;
  padding: 12px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  align-items: flex-start;

  &:hover {
    box-shadow: @default-box-shadow;
  }

  img {
    width: 110px;
    border-radius: 2px;
    .desktop({
      width: 176px;
    });
  }
}

.textContainerHorizontal {
  margin-left: 12px;
}
.textContainerVertical {
  margin-top: 20px;
}

.tag {
  color: @color-emperor;
  font-weight: @proxima-regular;
  margin-bottom: 8px;
}
.tagVertical {
  .tag;
  font-size: 16px;
  line-height: 20px;
}
.tagHorizontal {
  .tag;
  font-size: 14px;
  line-height: 18px;
}

.titleVertical {
  .text-size--regular(@zui-fontsize-regular);
  font-weight: @proxima-semi-bold;
  color: @color-black;
}
.titleHorizontal {
  .text-size--compact(@zui-fontsize-small);
  margin-top: @spacing-xxs;
  font-weight: @proxima-semi-bold;
  color: @color-black;

  .desktop({
    .text-size--compact(@zui-fontsize-regular);
    margin-top: @spacing-xs;
  });
}
