@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

.container {
  padding-top: 60px;
  padding-bottom: 80px;
  .tablet({
    padding-top: 40px;
    padding-bottom: 60px;
  });
  .mobile({
    padding: 40px 0;
  });
}
.carousel {
  margin-top: 20px;
  position: relative;
  .mobile({
      display: none;
    });
  margin-left: -16px;
}

.mobileContainer {
  margin-top: 20px;
  .not-mobile({
        display: none;
    });
}

.articleCarousel {
  height: 100%;
}
.articleCarouselSpacing {
  padding: 0 16px;
}
.articleMobileSpacing {
  margin-bottom: 20px;
}

@title-height: calc(32px + 20px);
@button-height: calc(30px / 2);
.arrow {
  position: absolute;
  top: calc(50% + @button-height - @title-height);
}

.rightArrow {
  right: 0;
}
.leftArrow {
  left: 0;
}

.h1Cr {
  .mobile({
    .text-size--regular(@zui-fontsize-larger);
    max-width: 350px;
  });
}
