@import (reference) '~@zola/zola-ui/src/styles/common/_variables.less';
@import (reference) '~@zola/zola-ui/src/styles/common/_mixins.less';

// DO NOT MODIFY ANY LIBRARY CODE BELOW THIS LINE UNLESS YOU HAVE A GOOD REASON
.carousel {
  overflow: hidden;
  min-height: 280px;
  display: flex;
  .mobile({
    flex-wrap: wrap;
  });
  :global {
    .react-multi-carousel-list {
      display: flex;
      align-items: center;
      overflow: hidden;
      position: unset;
    }

    .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }

    .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 35px;
      border: 0;
      //background: rgba(0, 0, 0, 0.5);
      // width: 36px;
      // height: 36px;
      opacity: 1;
      cursor: pointer;
    }

    .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 0;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .react-multi-carousel-dot button {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      opacity: 1;
      padding: 5px 5px 5px 5px;
      box-shadow: none;
      transition: background 0.5s;
      border-width: 2px;
      border-style: solid;
      border-color: grey;
      padding: 0;
      margin: 0;
      outline: 0;
      cursor: pointer;
    }

    .react-multi-carousel-dot button:hover:active {
      background: #080808;
    }

    .react-multi-carousel-dot--active button {
      background: #080808;
    }

    .react-multi-carousel-item {
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }

      .react-multi-carousel-track {
        overflow: visible !important;
      }
    }
  }
}

.arrow {
  position: absolute;
  top: 50%;
}
